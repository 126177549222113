import { render, staticRenderFns } from "./ValesView.vue?vue&type=template&id=0b0ce7d8&scoped=true"
import script from "./ValesView.vue?vue&type=script&lang=js"
export * from "./ValesView.vue?vue&type=script&lang=js"
import style0 from "./ValesView.vue?vue&type=style&index=0&id=0b0ce7d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0ce7d8",
  null
  
)

export default component.exports