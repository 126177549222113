<template>

  <div class="container-fluid px-0">
    <div class="container-fluid">
      <div class="bg-holder bg-dark " > </div>
      <div class="row flex-center position-relative min-vh-100 g-0 py-5">
        <div class="col-11 col-sm-10 col-xl-4">
          <div class="card border border-300 auth-card">
            <div class="card-body">
              <div class="row align-items-center gx-0 gy-7">
                
                <div class="col mx-auto">
                  <form @submit.prevent="validarUsuario()" v-if="!clienteValidado">
                    <div class="auth-form-box">
                      <div class="text-center ">
                          <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                            <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                              <img src="@/assets/logo-large.png" alt="IASC" width="208" />
                          </div>
                        </a>
                        <h3 class="text-1000 p-2">FACTURACIÓN</h3>
                        <h5 class="text-1000 p-2">VALES DIESEL</h5>
                      </div>
                      <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="email">Usuario</label>
                        <div class="form-icon-container">
                          <input class="form-control  form-icon-input" id="usuario" v-model="usuario" type="text" placeholder="USUARIO" /><span class="fas fa-user text-900 fs--1 form-icon"></span>
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="password">Contraseña</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="password" v-model="password" type="password" placeholder="Contraseña" /><span class="fas fa-key text-900 fs--1 form-icon"></span>
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="email">Email</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="email" v-model="email" type="email" placeholder="nombre@ejemplo.com" /><span class="fas fa-user text-900 fs--1 form-icon"></span>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-primary w-100 mb-3">Iniciar Sesión</button>
                    </div>
                  </form>


                  <form v-if="clienteValidado" @submit.prevent="validar()">
                    <div class="auth-form-box">
                      <div class="text-center ">
                          <a class="d-flex flex-center text-decoration-none mb-4" href="/">
                            <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                              <img src="@/assets/logo.png" alt="IASC" width="100" />
                          </div>
                        </a>
                      
                        <h3 class="text-1000 p-2">Conexión a SQL Server</h3>
                      </div>
                      <div class="alert alert-danger" v-if="msg_err" role="alert">
                        {{msg_err}}
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="email">Extensión</label>
                        <div class="form-icon-container">
                          <input class="form-control  form-icon-input text-uppercase" v-model="$ext" type="text" readonly disabled />
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                          <label class="form-label">Empresa</label>
                          <select class="form-control" v-model="logEmpresa" style="height: 34px !important;" >
                            <option v-for="(empresa, key) in empresas" :key="key" :value="empresa" :selected="key == 0 ? true : false">{{empresa.cianombre}}</option>
                          </select>
                      </div>
                  
                      <div class="mb-3 text-start">
                        <label class="form-label" for="servidor">Servidor</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="servidor" v-model="logEmpresa.servidor" type="text"   />
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="basedatos">Base de Datos</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="basedatos" v-model="logEmpresa.bd" type="text"   />
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="usuario">Usuario</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="usuario" v-model="logEmpresa.bdusuario" type="text"  /><span class="fas fa-user text-900 fs--1 form-icon"></span>
                        </div>
                      </div>
                      <div class="mb-3 text-start">
                        <label class="form-label" for="pass">Contraseña</label>
                        <div class="form-icon-container">
                          <input class="form-control form-icon-input" id="pass" v-model="logEmpresa.bdpassword" type="password"  /><span class="fas fa-key text-900 fs--1 form-icon"></span>
                        </div>
                      </div>
                      
                      <div class="d-flex justify-content-between">
                        <button type="submit" class="btn btn-primary w-100 mb-3">Conectar</button>
                        <button type="button" @click="clienteValidado=false" class="btn btn-outline-primary w-100 mb-3">Regresar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
name: 'LoginView',
data(){
    return{
        email: '',
        password: '',
        usuario: '',
        msg_err: '',
        recuerda_token: true,
        show:false,
        clienteValidado:false,
        empresas:[],
        logEmpresa:{}
    }
},
methods:{
    validarUsuario(){
        if(!this.usuario){
            this.msg_err = 'ingrese un usuario'
        }else if(!this.email){
            this.msg_err = 'ingrese un correo'
        }else if(!this.password){
            this.msg_err = 'ingrese una contraseña'
        }else{
            this.msg_err = ''
            this.login()
        }
    },
    async login(){
      let data = {
          usuario: this.usuario,
          email: this.email,
          password: this.password,
          recuerda_token: this.recuerda_token,
          extension: this.$ext
      }
      await fetch(this.$apiCte+'/logiasc', {
              method: "POST",
              headers: { "Content-Type": "application/json"},
              body: JSON.stringify(data) 
          }).then((response)=>{
              if(response.ok){
                  return response.json();
              }   
          }).then((res)=>{
              res = JSON.parse(res)
              if(res.result == 401){
                  this.msg_err = res.msg
                  return
              }

              let data = res
                if(data.token){
                    this.msg_err= ''
                    this.$store.dispatch('saveToken',data.token)
                    this.empresas = data.empresas
                    this.logEmpresa = this.empresas[0]
                    this.clienteValidado = true
                  }
          }).catch((err)=>{
              this.falseToken(err)
              this.isLoading = false
          });
    },
    async validar(){
      this.logEmpresa.token = this.$store.state.iascdietkn 
      await fetch(this.$apiCte+'/exp', {
              method: "POST",
              headers: { "Content-Type": "application/json",
              'Authorization' : this.$store.state.iascdietkn },
              body: JSON.stringify(this.logEmpresa) 
          }).then((response)=>{
            if(response.ok){
              return response.json();
            }   
          }).then((res)=>{
            res = JSON.parse(res)
           
            if(res.result == 401){
                  this.msg_err = res.msg
                  return
            }
            let data = res
           
            if(data.log){
              this.msg_err= ''
              this.$store.dispatch('saveServerInfo',data.serverinfo)
              this.$router.push('/').catch(() => {});
            }
          }).catch((err)=>{
              this.falseToken(err)
              this.isLoading = false
          });
    },
    falseToken(err){
            console.log('Error iasc: '+ err)
            if(err == '{message: expired token}'){
                this.$store.dispatch('logout')
                if(this.$route.name == 'login') return
                this.$router.push({name: 'login'})
            }else{
                fetch(this.$api+'/err', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" ,
                               'Authorization' : this.$store.state.iascdietkn },
                    body: JSON.stringify({  error: err })
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then(()=>{ })
            }
          },
}
}
</script>
<style scoped>
  #app{
    margin-top: 0px !important;
  }

  .card{
    border-radius: 10px;
  }
  
</style>