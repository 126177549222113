import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index'
import { jwtDecode } from "jwt-decode";
import LoginView from '@/components/LoginView.vue'
import ValesView from '@/components/ValesView.vue'

Vue.use(VueRouter);

export const routes = [
    {path: '/login', name: 'login', component: LoginView, meta: {requiresAuth: false}},
    {path: '/',      name: 'vales',  component: ValesView, meta: {requiresAuth: true}} 
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_SUBFOLDER,
    routes
  })
  
  router.beforeEach((to, from, next) => {
    if(to.matched.some(item=>item.meta.requiresAuth)){
      if(!store.state.iascdietkn){
        next({name: 'login'})
      }
      try{
        jwtDecode(store.state.iascdietkn)
      }catch(error){
        next({name: 'login'})
      }
      if(jwtDecode(store.state.iascdietkn).exp*1000 <= new Date().getTime()){
        next({name: 'login'})
      }
      next()
    }else{
      next()
    }
  })
  
export default router