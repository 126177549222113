import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
        iascdietkn: JSON.parse(localStorage.getItem('iascdietkn')),
        serverinfo: JSON.parse(localStorage.getItem('serverinfo')),
    },
    mutations: {
      setToken(state,token){
        state.iascdietkn = token
      },
      setServerInfo(state,info){
        state.serverinfo = JSON.stringify(info)
      }
    },
    actions: {
      saveToken({commit},token){
        commit('setToken',token)
        localStorage.setItem('iascdietkn',JSON.stringify(token))
      },
      saveServerInfo({commit},info){
        commit('setServerInfo',info)
        localStorage.setItem('serverinfo',JSON.stringify(info))
      },
      logout({commit}){
        commit('setToken',null)
        localStorage.clear()
      }
    }
});