import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import { Buffer } from 'buffer';

Vue.config.productionTip = false
window.Buffer = Buffer;
const empresas = [{extension: 'test', url: 'https://transportes.sistemasfussion.org'}, //'https://iasc.dnsalias.net'
                  {extension: 'cst', url: 'http://canoandson.dynalias.net:86'},
                  {extension: 'avm',  url: 'https://server.test'}]           
const ext = window.location.hostname.split('-')[0]
const exttmp =  empresas.find(e => e.extension == ext)
const exttest = empresas.find(e => e.extension == 'test')
const extension =  (exttmp == undefined ? exttest  : exttmp)

Vue.prototype.$apiCte = extension.url + '/TransporteWeb'           //variable global
Vue.prototype.$token = localStorage.getItem('iascdietkn')  //variable global
Vue.prototype.$api = 'https://api-transporte.ingavanzada.com'  //variable global
Vue.prototype.$ext =  extension.extension

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')