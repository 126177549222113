<template>
  <div id="app">
    <main class="main" id="top">
      <div class="container-fluid px-0" data-layout="container">
          <router-view />
      </div>
    </main>
  </div>
</template>
<script>
  
export default {
  name: 'App',
  data(){
    return{
      year: new Date().getFullYear(),
    }
  }
}
</script>

<style>
#app {
  text-align: center;
}
</style>
