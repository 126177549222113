<template>
  <div>
  
  <section class="container ">
    <div>
      <img src="@/assets/logo-large.png" alt="IASC" style="width:40%" />
      <nav :class="{floatingmenu: true,open: openMenu }">
        <div class="text-end pe-3">
          <a href="#" class="closebtn" @click="openMenu =false">&times;</a>
        </div>
        <ul :class="{mainmenu:true, onview:openMenu}">
            <li>
                <a href="#" @click="getHistorial();" class="ripple">
                    <i class="fas fa-history fa-lg pe-2"></i> Historial
                </a>
            </li>
            <li>
                <a href="#" @click="logout()" class="ripple">
                    <i class="fa fa-sign-out fa-lg pe-2" ></i> Cerrar Sesión
                </a>
            </li>
            
        </ul>
        <div class="menu-bg"></div>
    </nav>
    <button class="floating btn btn-secondary" @click="openMenu = true" >
      <div></div>
      <div></div>
      <div></div>
    </button>
    </div>
      <div class="content">
          <div>
              <div v-if="isLoading" class="spinner"></div>
              <div class="row ">
                  <div class="col-12 p-0">
                      <div class="card" >
                          <div class="card-content" >
                              <div class="card-body">
                                  <div class="card-header"  >
                                      <h5 class="card-title">{{ titulo }}</h5>
                                  </div>
                                  <div class="row" v-if="!success">
                                      <div class="col-12">
                                          <form>
                                            
                                            <div class="row">
                                                <label class="col-3 text-start text-nowrap ">Fecha: </label>
                                                <div class="col-8 text-start"><p>{{ vale.fecha }}</p></div>
                                                <div class="col-12 " v-show="false">
                                                    <input type="date" class="form-control form-control-sm" required disabled v-model="vale.fecha" />													
                                                </div>
                                            </div>	
                                           
                                            <div class="row">
                                              <label class="col-12 text-nowrap text-start">Tipo</label>
                                              <div class="col-12">
                                                <select class="form-control form-control-sm" v-model="vale.tipo">
                                                  <option value="S">Simple</option>
                                                  <option value="M">Multiple</option>
                                                </select>
                                              </div>
                                            </div>
                                            
                                            <div class="row">
                                              <label class="col-12 text-nowrap text-start">Operador</label>
                                              <div class="col-12">
                                                <model-list-select
                                                  :list="operadores"
                                                  v-model="vale.operador"
                                                  option-value="numero"
                                                  option-text="nombre"
                                                  id="mySelectId"
                                                  name="mySelectName"
                                                  placeholder="Busca operador..."
                                                  @blur="handleBlur"
                                                >
                                                </model-list-select>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <label class="col-12 text-nowrap text-start">Unidad</label>
                                              <div class="col-12">
                                                <model-list-select
                                                  :list="unidades"
                                                  v-model="vale.unidad"
                                                  option-value="numero"
                                                  option-text="descripcion"
                                                  id="mySelectUnidad"
                                                  name="mySelectName"
                                                  placeholder="Busca unidad..."
                                                  @blur="handleBlur"
                                                >
                                                </model-list-select>
                                              </div>
                                            </div>
                                              
                                              <div class="row ">
                                                  <label class="col-12 text-start text-nowrap">Litros</label>
                                                  <div class="col-12">
                                                      <input type="number" class="form-control form-control-sm" required v-model="vale.litros" />													
                                                  </div>
                                              </div>	
                                          
                                          </form>
                                      </div>
                                      <div class="col-12 pt-5">
                                          <button @click="setVale()" class="btn btn-sm btn-success btn-block">Genera Vale</button>
                                          
                                      </div>
                                  </div>
                                  <div class="row" v-else>
                                      <div class="col-12">
                                        <div class="row">
                                            <p class="col-12 p-4 bold text-center ">{{respuestaGeneraVale.informacion}}</p>
                                            <div class="col-6 m-auto pb-5">
                                              <button @click="resetVale()" type="button" class="btn btn-sm p-2 btn-outline-success text-uppercase">Generar Otro Vale</button>
                                            </div>
                                        </div>	
                                      </div>
                                      <div class="col-6 m-auto pt-5">
                                          <ul class="p-1 list-unstyled controls-form">
                                            <li><button type="button" @click="logout()" class="btn btn-sm btn-outline-danger btn-block">Cerrar Sesión</button></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <InfoPopUpVue  v-show="showHistorial" @close="showHistorial=false">
          <template v-slot:header>
              <h6 class="text-uppercase">HISTORIAL</h6>
          </template>
          <template v-slot:body>
              <div class="row p-2" style="font-size:18px;">
                <div class="col-12">
                  <div class="row" v-for="(historia,x) in historial" :key="x">
                    <div class="col-12 justify-content-between d-flex text-nowrap">
                      <i class="fa-solid fa-calendar-day"></i> {{ formatDateTime(historia.fecha).date  }} <br>
                      <i class="fa-regular fa-clock"></i> {{ formatDateTime(historia.fecha).time  }} 
                    </div>
                    <div class="col-12 text-start"><b><small>Usuario: {{ historia.usuario }}</small></b> <br>{{ historia.nota }}</div>
                    <div class="col-12 p-2"><hr></div>
                  </div>
                </div>
              </div>
          </template>
      </InfoPopUpVue>

      <InfoPopUpVue  v-show="showNotification" @close="closeInfo">
          <template v-slot:header>
              <h6 class="text-uppercase">{{notificationTitle}}</h6>
          </template>
          <template v-slot:body>
              {{notificationText}}
          </template>
      </InfoPopUpVue>
  </section>
  </div>
</template>

<script>
import InfoPopUpVue from './InfoPopUp.vue';
import { ModelListSelect } from "vue-search-select"
export default {
  components:{InfoPopUpVue,ModelListSelect},
  data(){
      return{
          id: 0,
          modo: 'Creacion', 
          vale: {},
          showHistorial:false,
          titulo: 'Genera Vale de Diesel - UltraGas',
          isLoading: false,
          operadores:[],
          unidades:[],
          success: false,
          showNotification:false,
          notificationTitle: '',
          notificationText: '',
          respuestaGeneraVale: {},
          objectItem: {
            code: "",
            name: "",
            desc: "",
          },
          historial:[],
          openMenu:false,
      }
  },
  mounted(){
    this.getDatos()
    this.vale.tipo = 'S'
    this.vale.fecha =  new Date().toISOString().slice(0,10)
  },
  methods:{
    formatDateTime(datetime) {
      const dateObj = new Date(datetime);
      const date = dateObj.toLocaleDateString("es-ES"); // Formato de fecha
      const time = dateObj.toLocaleTimeString("es-ES"); // Formato de hora
      return { date, time };
    },
    handleBlur() {
      console.log('')
    },
    resetVale(){
      this.respuestaGeneraVale = {}
      this.success = false
      this.resetForm()
    },
    resetForm(){
      this.vale = {}
      this.vale.tipo = 'S'
      this.vale.fecha =  new Date().toISOString().slice(0,10)
    },
    closeInfo(){
          this.showNotification = false
          this.notificationTitle =''
          this.notificationText = ''
      },
      setRequired(req){
        req.forEach((r)=>{
          try {
            this.$refs[r].setAttribute('required','')
          } catch (error) {
            try{
              document.querySelector('#'+r).setAttribute('required','')
            }
            catch(error){
              //
            }
          }
        })
      },
      logout(){
        this.$store.dispatch('logout')
        this.$router.push({name: 'login'})
      },
      falseToken(err){
            console.log('Error iasc: '+ err)
            if(err == '{message: expired token}'){
                this.$store.dispatch('logout')
                if(this.$route.name == 'login') return
                this.$router.push({name: 'login'})
            }else{
                fetch(this.$api+'/err', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" ,
                               'Authorization' : this.$store.state.iascdietkn },
                    body: JSON.stringify({  error: err })
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then(()=>{ })
            }
          },
      getVale(){
          let req = []
          this.isLoading = true
           fetch(this.$api+'/rdx', {
              method: "POST",
              headers: { "Content-Type": "application/json",
                          'Authorization' : this.$store.state.iascdietkn },
              body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "Diesel", id: this.id })
          }).then((response)=>{
              if(response.ok){
                  return response.json()
              }   
          }).then((data)=>{
              this.vale = data['res']
              req = data['req']
          }).finally(()=>{
              this.setRequired(req)
              this.isLoading = false
          }).catch((err)=>{
              this.falseToken(err)
          });
      },
      getDatos(){
        fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iascdietkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Diesel", metodo: 'getDatos', 
                                        params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
                console.log(response)
            }).then((data)=>{
                this.operadores = data.operadores
                this.unidades = data.unidades
            }).catch((err)=>{
                this.falseToken(err)
            })
      },
      getHistorial(){
        fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iascdietkn },
                body: JSON.stringify({  accion: "metodo", archivo: "Diesel", metodo: 'getHistorial', 
                                        params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
                console.log(response)
            }).then((data)=>{
                this.historial = data.historial
                this.showHistorial = true
                this.openMenu = false
            }).catch((err)=>{
                this.falseToken(err)
            })
      },
      setVale(){
          this.isLoading = true
          fetch(this.$api+'/rdx', {
              method: "POST",
              headers: { "Content-Type": "application/json",
                          'Authorization' : this.$store.state.iascdietkn },
              body: JSON.stringify({  accion: 'metodo', archivo: "Diesel", metodo:'UltraGas_GenerarVale', params: {vale: this.vale }}) 
          }).then((response)=>{
              if(response.ok){
                  return response.json();
              }   
          }).then((data)=>{
            console.log(data)
              if(data['error'] == true){
                  this.showNotification = true
                  this.notificationTitle =  'Error'
                  this.notificationText = data['msg']
                  this.success = false
              }else{
                  this.respuestaGeneraVale = data
                  this.success = true
              }
              this.isLoading = false
          }).catch((err)=>{
              this.falseToken(err)
              this.isLoading = false
          });
      },
   
  }
}
</script>

<style scoped>

nav .closebtn {
 text-align: right;
  font-size: 36px;
  text-decoration: none;
  color: white;
}
.content{
  min-height: unset;
  padding-bottom:0;
}
  label{
    font-size:15px;
    font-weight: bold;
  }
  .card-title{
    font-size:20px !important;
    line-height: unset;
  }
  p{
    font-size:18px;
    margin-bottom:0
  }
  input{
    font-size:18px;
    padding: 7%;
    border-radius: 10px;
    
  }
  select{
    font-size:18px;
    padding: 0 7%;
    height: 2.5rem;
    border-radius: 10px;
    background-position-y: 7px !important;
  }
  .container{
    padding-top: 0;
    margin-top: 20px
  }
  .btn-sm{
    font-size:18px;
    padding: 1rem;
    text-transform: uppercase;
    border-radius: 10px;
  }
  .row{
    padding:.7rem 0
  }
  .card{
    border-radius: .7rem;
    border-left: 6px solid #009FDF;
    box-shadow: 0px 1px 9px -5px rgba(0, 0, 0, 0.69);
  }

  .floating{
    position: absolute;
    right: 0;
    top: 0%;
    padding: 1rem;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .floating div {
    width: 30px;
    height: 3px;
    background-color: gainsboro;
    margin: 5px 0;
}
.floatingmenu{
    border-radius: 10px;
    z-index: 999;
    right: -101%;
    position: fixed;
    display: inline-block;
    top: 17%;
    background-color: currentcolor;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: right .5s ;
}
.mainmenu {
    margin: 0;
    padding-left: 0;
    list-style: none;
   
}
.mainmenu li {
  margin: 1rem 0;
  text-align: left;
}
.mainmenu li a {
    display: block;
    padding: 16px;
    color: gainsboro;
    border-radius: 50px;
    position: relative;
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    font-size: 20px;
    text-decoration: none;
}
  .ui.fluid.search.selection.dropdown{
    font-size:16px;
    border-radius: 10px;
  }

  .fa-lg{
    font-size: 20px;
  }
  small {
    font-size: 93%;
}

.floatingmenu.open {
  right:0;
}

</style>